.imgRow {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0px;
    padding-left: 1%;
    padding-right: 1%;
  }
  
  /* Create four equal columns that sits next to each other */
  .imgColumn {
    flex: 25%;
    max-width: 25%;
    padding: 0 0px;
  }
  
  .imgColumn img {
    margin-top: 0px;
    vertical-align: middle;
    width: 100%;
  }
  
  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .imgColumn {
      flex: 50%;
      max-width: 50%;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .imgColumn {
      flex: 100%;
      max-width: 100%;
    }
  }
